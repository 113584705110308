import React from 'react'
import PropTypes from 'prop-types'

class Layout extends React.Component {
  setWhProperty = () => {
    document.documentElement.style.setProperty(
      '--wh',
      `${window.innerHeight}px`
    )
  }

  resizeEvent = () => {
    this.setWhProperty()
  }

  componentDidMount = () => {
    this.setWhProperty()
    window.addEventListener('resize', this.resizeEvent)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent)
  }

  render() {
    const { children } = this.props
    return <div className="font-sans text-base antialiased">{children}</div>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
